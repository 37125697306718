$amount: 4;
$iconBg: $dark;
$iconColor: $light;
$contactsBg: $medium;
$contactsColor: $light;
$desktopPosition: 200px;

//////////////////////////////////////////////////////////////// 

@import "../modules/fixedButtonsMixins";

.fixedContacts {
	>li {
		&:before {
			@include before;
			background: $iconBg;	
			color: $iconColor;
		}

		// Icon Examples
		&.phone {
			&:before {
				@extend .icon;
				@extend .icon-phone;	
			}
		}

		&.email {
			&:before {
				@extend .icon;
				@extend .icon-plane;
			}
		}

		&.xing {
			background: rgb(0,103,105);
background: linear-gradient(180deg, rgba(0,103,105,1) 0%, rgba(0,135,140,1) 100%);
			&:before {
				background: url("../images/layout/xing.png") no-repeat center;	
			}
		}

		&.facebook {
			&:before {
				background: url("../images/layout/fb-light.png") no-repeat center/100%;	
			}
		}
	}

	// Mobile Styles
	@include breakpoint(large, max) {
		@include mobileFixedContacts;
		overflow: visible;
		border-radius: 5px;
		box-shadow: 6px -2px 15px rgba(0,0,0, .35);

		>li {
			@include mobileLi;
			background: $iconBg;
			cursor: pointer;
			overflow:visible;
			&:not(:last-child){
				border-right:$baseBorder;
			}

			&:before {
				@include mobileBefore;
				cursor: pointer;
			}
			
			// Content on Hover/Tab
			.inner {
				@include mobileInner;					
				padding: rem(30px) rem($baseGap);
				box-shadow: 6px -2px 15px rgba(0,0,0, .35);
				background: $contactsBg;
				color: $contactsColor;
				
				.phone {
					display: block;
					height: 100%;
					width: auto;
				}
			}
			
			// Hover usally does not need to be adjusted
			&:hover {
				.inner {
					opacity: 1;
					visibility: visible;
					transform: translate(0, -66px); // If the height of the bar is adjusted, you also need to adjust the transform
				}
			}

			&.hide {
				opacity: 0;
				transform: translate(0, -50px);
			}

			// Elements without Tab/Hover
			&.mobileNoHover {
				.inner {
					@include mobileNohoverInner;

					a {
						@include coverLink
					}
				}
			}
			
			&.mobileNoHover {
				// Define icon size e.g. social ícon
				&:before {
					max-width: 16px;
				}
				&.xing{
					&:before{
						max-width: 60px;
					}
				}
			}
		}
	}

	// Break styles
	@include breakpoint(large) {
		@include desktopFixedContacts
		top: $desktopPosition;

		>li {
			@include desktopLi;
			background: $contactsBg;
			color: $contactsColor;
			padding: 0 rem(40px);
			
			body.internetExplorer & {
				height: 100%; 
				min-width: 360px; // Set min width for IE
			}

			&.address {
				body.internetExplorer & {
					height: auto; // For certain li that have multiple lines
				}
			}

			@include nthOfType;
	
			.inner {
				white-space: nowrap; // Prevent the absolute content to be wrapped
			}

			&:before {
				@include desktopBefore;
				background: $iconBg;	
			}

			// Hover usally does not need to be adjusted
			&:hover {
				transform: none;
				right: 0;
			}

			&.noHover { // Remove or Set Element without hover
				@include desktopNoHover;
				background: $iconBg;
				&.xing{
					background:none;
					a{
						img{
							max-width: 60px;
						}
					}
				}

				body.internetExplorer & {
					min-width: auto;
				}

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					
					// Define icon size e.g. social icon
					img {
						max-width: 28px;
					}
				}
				
				// Removing mobile icon
				&:before {
					content: none;	
				}
			}
		}
		
	}
}