
// HEADER
//////////////////////////////
#pageWrap{
	overflow:hidden;
}
#header {
	position: relative;
	width: 100%;
	margin-top: 50px;
	.row{
		padding-top:4rem;
		padding-bottom:4rem;
		&:after{
			content:'';
			width:100vw;
			height:100%;
			position:absolute;
			top:0;
			left:0;
			opacity:.4;
			z-index:-1;
			background:url('../images/layout/bgHeader.jpg') no-repeat bottom center /cover;
			@include breakpoint(medium){
				left:calc(33.33% + 18px);
				width:73vw;
				opacity:1;
			}
			@include breakpoint(giant){
				left:calc(25% + 18px);
			}
		}
	}
	@include breakpoint($break){
		margin-top: rem(184px);
	}
	.branding {
		display: inline-block;
	}
	blockquote{
		font-family: $displayFont;
		font-size: rem(25px);
		line-height: rem(32px);
		padding-top:3rem;
		color:$primary;
		margin-bottom: 1.5rem;
		span{
			font-family: $mainFont;
			display:block;
			font-weight: bold;
			font-size: rem(14px);
			line-height: rem(26px);
			color:$alert;
		}
		@include breakpoint(giant){
			font-size: rem(32px);
			line-height: rem(40px);
		}
	}
	.btn{
		margin-bottom: 3rem;
	}
	.im{
		@include breakpoint(medium, max){
			padding:0;
		}
	}
	.lawyer{
		padding:rem(300px) .5rem 2rem .5rem;
		background:url('../images/layout/bgLawyer.jpg') no-repeat top center /cover;
		text-align: center;
		font-weight: bold;
		color:$light;
		position:relative;
		body:not(.index) &{
			padding:rem(300px) $baseGap 2rem $baseGap;
		}
		@include breakpoint(giant){
			padding:rem(400px) $baseGap 2rem $baseGap;
		}
		@include breakpoint(huge){
			padding:rem(500px) $baseGap 2rem $baseGap;
		}
	}
	
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
	.bgImage{
		position:relative;
		z-index:3;
		&:before{
			content:'';
			height:100%;
			width:75vw;
			background:url('../images/layout/bgTeaser.jpg') no-repeat left center /cover;
			position:absolute;
			bottom:0;
			right:rem(18px);
			z-index:-1;
			@include breakpoint(large, max){
				height:10rem;
				width:100vw;
				right:50%;
				transform:translateX(50%);
			}
			@include breakpoint(giant){
				width:50vw;
			}
		}
	}
	.teaser{
		background:$alert;
		padding:6.25rem 1.5rem 2.25rem 1.5rem;
		position:relative;
		margin-bottom:10rem;
		a{
			color:$light;
			text-decoration: none;
			color:rgba(255,255,255,.7);
			margin-bottom: 4rem;
			display: inline-block;
			max-width: 100%;
			span{
				color:$light;
				display:block;
				font-weight: bold;
				transition: all .3s ease-in-out;
				font-size: rem(22px);
			}
			&:hover{
				span{
					color:rgba(255,255,255,.7);
				}
			}
		}
		@include breakpoint(large, max){
			&:before{
				content:'';
				background:$alert;
				width:100vw;
				height:100%;
				left:50%;
				transform:translateX(-50%);
				z-index:-1;
				top:0;
				position:absolute;
			}
		}
	}
	.inner{
		padding:5.625rem 0;
		@include breakpoint(giant){
			padding:10.625rem 0;
		}
	}
	.timesAreaContent{
		position:relative;
		color:$light;
		padding:6.25rem 0;
		h4{
			font-size: 25px;
			color:$light;
		}
		h4, ul{
			margin-bottom: 0;
		}
		ul{
			span{
				@include breakpoint(giant, max){
					display:block;
				}
			}
		}
		&:before{
			content: '';
			position: absolute;
			left: -50vw;
			top: 0;
			width: 200vw;
			height: 100%;
			background-color: $medium;
			z-index: -1;
		}
	}
	.usp{
		color:$light;
		text-align: center;
		position:relative;
		@include responsiveImage('layout/bgUsp.jpg', (tiny, medium), true);

		&.lazyLoaded {
			background-size:cover
		}
		body.windows &{
			background-attachment:fixed;
		}
		.row{
			z-index:3;
			text-align: center;
			justify-content: center;
			blockquote{
				font-family: $displayFont;
				font-size: rem(32px);
				line-height: rem(40px);
				display:block;
				margin-bottom:1.5rem;
				span{
					font-family: $mainFont;
					display:block;
					font-weight: bold;
					font-size: rem(14px);
					line-height: rem(26px);
				}
			}
		}
		&:before{
			content:'';
			width:100%;
			height:100%;
			position:absolute;
			top:0;
			left:0;
			z-index:2;
			background:rgba(0,0,0,.6);
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.inner{
		padding:5.625rem 0;
		@include breakpoint(giant){
			padding:10.625rem 0;
		}
	}
	ul, p{
		margin-bottom: 2rem;
	}
	ul{
		&:not(.unstyledList){
			li{
				padding-left: rem(15px);
				margin-bottom: rem(7px);
		
				&:before {
				@extend .icon;
				@extend .icon-angle-right;
				text-indent: rem(-15px);
				}
			}
		}
	}
	.large-auto{
		padding-right:0;
		@include breakpoint(large, max){
			padding-left:0;
		}
	}
	iframe{
		height:rem(400px) !important;
		float:left;
		@include breakpoint(large){
			height:rem(863px) !important;
		}
		@include breakpoint(large, max){
			margin-bottom:80px;
		}
	}
}