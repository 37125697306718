/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 24px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: "Spartan", sans-serif;

$displayFont: "Playfair Display", sans-serif;

$iconFont: "icomoon",sans-serif;

// Project colors

$primary: #1a1a1a;
$secondary: #525a5c;
$light: #fff;
$medium: #999a8c;
$dark: #52524b;
$border: #bbb;
$alert: #2a4a96;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	phone: ""\f095"", 
	plane: ""\f1d8"", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
);

