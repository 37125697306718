// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none; 
	color: $navColor;
	transition: all .3s ease-in-out;
	@include breakpoint(huge){
		padding: 1em .5rem;
	}
	@include breakpoint($break, max){
		border-bottom:$baseBorder;
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $alert;
	@include breakpoint($break){
		color:$alert;
		background-color: transparent;
	}
}
%buttonActive {
	// Link Active
	color: $light;
	font-weight: bold;
	background-color: $alert;
	@include breakpoint($break){
		color:$alert;
		background-color: transparent;
		font-weight: bold;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 57px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 10001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include has-sub {
						@include sub {
							background: $medium;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
							li{
								a{
									color:$light;
									
								}
							}
						}
					}
					@include navBack {
						background:$dark;
						color:$light;
					}
				}
			}
		}
	}
}
@include breakpoint($break, max){
	#navigation{
		.col{
			padding:0;
		}
		.large-6{
			display:none;
		}
	}
	nav{
		ul{
			padding-bottom:6rem;
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		position:fixed;
		transition: all .3s ease-in-out;
		.flexBox{
			margin-left: auto;
			margin-right: auto;
			max-width: rem($rowMaxWidth);
			width: 100%;
			position: relative;
			display: flex;
			flex: 0 1 auto;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: stretch;
			.branding{
				background:url('../images/layout/branding-dark.png') no-repeat;
				img{
					opacity:1;
					transition: all .3s ease-in-out;
				}
			}
			.col{
				display:flex;
				flex-direction: column;
				justify-content: center;
				&.alignCenter{
					padding-right:0;
					@include breakpoint(giant){
						padding-right:$baseGap;
					}
				}
				.contactBox{
					background:$medium;
					color:$light;
					padding-top:3rem;
					padding-bottom:3rem;
					h3{
						font-weight: 400;
						margin-bottom: 0;
						font-family: $mainFont;
						color:$light;
						font-size: rem(26px);
					}
					ul{
						font-size: rem(14px);
						a{
							color:$light;
							&:hover{
								text-decoration: none;;
							}
						}
					}
				}
			}
		}
		&.in{
			background:$dark;
			.branding{
				img{
					opacity:0;
				}
			}
			nav{
				ul{
					&.navi{
						li{
							a, span{
								color:$light;
								&:hover, &:focus, &.active{
									opacity:.7;
									color:$light;
								}
							}
						}
					}
				}
			}
		}
		nav {
			ul {
				&.navi {
					@include breakpoint(giant){
						flex-wrap:wrap;
						justify-content: flex-start;
						li{
							&:nth-child(4){
								margin-right:2.5rem;
							}
						}
					}
					@include breakpoint(huge){
						flex-wrap:nowrap;
						justify-content: space-between;
						li{
							&:nth-child(4){
								margin-right:0;
							}
						}
					}
					li {
						&.has-sub {
							.sub {
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $dark;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 10000;
	height:50px;

	.branding{
		img{
			max-height:50px;
			width:auto;
		}
	}
	li{
		&:first-child{
			padding-right:1rem;
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {

	li {
		a{
			@include breakpoint(small, max){
				font-size: rem(15px);
			}
			&:hover{
				color:$alert;
			}
			&.active{
				color:$alert;
				font-weight: bold;
			}
		}
	}
}